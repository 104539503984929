import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"
import Logo from "../../../content/assets/img/logo-header.jpg"
import { createGlobalStyle } from "styled-components"
import styled from "styled-components"
import { theme } from "../../theme"
const isClient = typeof window !== "undefined"
const GlobalStyles = createGlobalStyle`
  a{
    text-decoration: none;
    box-shadow: none;
    margin-right: 1rem;/
  }
  .navbar{
    border-bottom: 1px solid ${theme.themeColor};
    position: ${
      isClient && window.location.pathname === "/" ? "relative" : "relative"
    };
    background-color: ${
      isClient && window.location.pathname === "/" ? "#fbfdfc" : "#fbfdfc"
    };
    padding:0;
    
    width:100%;
  }
  .nav-link{
    font-family:LeagueSpartanBold; 
    color:${theme.themeColor}!important; :hover{
    color:${theme.secundaryColor}!important;
  }}
  .navbar-collapse{
    @media (max-width: 768px) {
      z-index: 99;
    }
    
  }
  .navbar-toggler{
    @media (max-width: 768px) {
     margin-right:20px;
    }
  }
  #basic-navbar-nav{
    flex-direction: row;
    justify-content: end;
    padding-left: 40px;
        margin-top: 1rem;
  }
`

const Header = () => {
  return (
    <Navbar expand="lg">
      <GlobalStyles />
      <Navbar.Brand style={{ padding: 0 }}>
        <Img alt="logo" src={Logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav.Link style={{ boxShadow: "none" }} href="/">
            Inicio
          </Nav.Link>
          <Nav.Link
            style={{ boxShadow: "none" }}
            href="/directivos"
          >
            Directivos
          </Nav.Link>
          <Nav.Link style={{ boxShadow: "none" }} href="/socios">
            Asociarme
          </Nav.Link>
          <Nav.Link style={{ boxShadow: "none" }} href="/beneficios">
            Beneficios
          </Nav.Link>
          <Nav.Link style={{ boxShadow: "none" }} href="/noticias">
            Noticias
          </Nav.Link>
          <Nav.Link style={{ boxShadow: "none" }} href="/contacto">
           Contacto
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

const Img = styled.img`
  margin-bottom: 0px;
  width: 20rem;
  @media (max-width: 768px) {
    width: 14rem;
    padding-left:0px;
  }
`

export default Header
